import React from 'react'
import './Who.css'

const Who = () => {
    return (
        <div className='whoContainer'>
            <div className='headingText'>Who We* Are</div>


            Well, that's kind of misleading 😅 because Foodii is a passion project made by a team of one, yup, just me! I am an avid foodie and have always wished for a service that would do all the things I plan for this application and I know so many others do too.
            The World of eating out continues to grow every single day but the tools we use to keep in touch with all of these new discoveries don't seem to be keeping up and so many cool places we'd like to visit get lost in our TikTok and Insta bookmarks never to be seen again 😖.
            <br></br> That is why I took on this task of building the perfect application to combat that and make the lives of us food lovers that much easier 😁
            <br></br><br></br> I am a recent graduate 🎓 but despite my limited commercial experience, I am driven by my love for food and my belief that Foodii has the potential to positively impact the way people discover, organise and enjoy new dining experiences, with the help of a community that understands the benefits of my app
            <br></br><br></br> If you have any suggestions or encounter any issues, please don't hesitate to reach out to me at thefoodieofficial@gmail.com. I am eager to continue improving and growing Foodii and am excited for what the future holds.


        </div>
    )
}

export default Who