import React from 'react'
import './TermsConditions.css'

const TermsConditions = () => {
    return (
        <div className='termsContainer'>
            <div className='headingText'>Terms and Conditions</div>

            Please read these terms and conditions carefully before creating a new account on our mobile application, designed to store users data such as images, opinions, and ratings of food and restaurants. By creating an account, you agree to be bound by these terms and conditions.

            <br></br><br></br> <span className='boldText'>User Data:</span> Our mobile application collects and stores information provided by you, including but not limited to your name, email address, and location data. You are responsible for ensuring that all information provided by you is accurate, up-to-date, and complies with all applicable laws and regulations.

            <br></br><br></br> <span className='boldText'>Use of User Data:</span>  The user data collected by our mobile application will be used to provide the services offered through the application, including but not limited to location-based services, personalized content recommendations, and the storage of images, opinions, and ratings. We may also use this data for research and analytical purposes, to improve our services, and to provide you with advertising and marketing communications. We will comply with all applicable privacy laws and regulations, including the EU's General Data Protection Regulation (GDPR).

            <br></br><br></br> <span className='boldText'>Images:</span>  Our mobile application allows you to upload images, which will be stored on our servers. You are solely responsible for ensuring that you have the right to upload and store any images that you submit through the application, and that these images do not infringe on the intellectual property rights of any third parties. You grant us a perpetual, non-exclusive, worldwide, royalty-free license to use, copy, modify, and display these images for the purpose of providing our services.

            <br></br><br></br> <span className='boldText'>Intellectual Property:</span>  The content, logos, images, trademarks and other intellectual property used in our mobile application are the property of Foodii or its licensors and are protected by copyright, trademark, and other intellectual property laws. Any unauthorized use of these materials is strictly prohibited and may result in legal action. By using our mobile application, you acknowledge and agree that all intellectual property rights in the mobile application and its content are and shall remain the property of Foodii.

            <br></br><br></br> <span className='boldText'>Location Data:</span>  Our mobile application collects and stores your location data, which is used to provide location-based services and to improve the functionality of the application. You may choose to turn off location services at any time through the settings of your device. By providing us with your location data, you grant us the right to use this data for the purposes outlined above.

            <br></br><br></br> <span className='boldText'>Third-Party Services:</span>  Our mobile application may include links to third-party websites and services. We are not responsible for the privacy practices of these third-party services, and we encourage you to review their terms and conditions and privacy policies.

            <br></br><br></br> <span className='boldText'>Advertising:</span>  Our mobile application may display advertisements from third-party advertisers. We do not endorse any advertisements or the products or services that are advertised, and we are not responsible for any loss or damage that may result from your interaction with these advertisements.

            <br></br><br></br> <span className='boldText'>Changes to Terms and Conditions:</span>  We reserve the right to modify these terms and conditions at any time, and your continued use of our mobile application constitutes your agreement to any such modifications. We will make reasonable efforts to notify you of any material changes to these terms and conditions, such as by posting a notice on our mobile application or sending an email to the email address associated with your account.

            <br></br><br></br> <span className='boldText'>Termination of Account:</span>  We reserve the right to terminate your account at any time, for any reason, and without prior notice. You may also choose to terminate your account at any time by contacting us through the support channels provided within the app.

            <br></br><br></br> <span className='boldText'>Disclaimer:</span>  Our mobile application is provided on an "as is" and "as available" basis, and we make no representations or warranties of any kind, express or implied, as to the operation of the application or the information, content, materials, or products included on the application.

            <br></br><br></br> <span className='boldText'>Limitation of Liability:</span>  Under no circumstances will we be liable for any damages, including without limitation direct or indirect, special, incidental, or consequential damages, losses or expenses arising in connection with our mobile application or your use thereof or inability to use by any party, or in connection with any failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure, even if we are advised of the possibility of such damages, losses or expenses.

            <br></br><br></br> <span className='boldText'>Indemnification:</span>  You agree to indemnify and hold us and our affiliates, directors, officers, agents, and employees harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of our mobile application, your violation of these terms and conditions, or your violation of any rights of another.

            <br></br><br></br> <span className='boldText'>Governing Law:</span>  These terms and conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which our mobile application is headquartered, without giving effect to any principles of conflicts of law.

            <br></br><br></br> <span className='boldText'>Severability:</span>  If any provision of these terms and conditions is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.

            <br></br><br></br> <span className='boldText'>Entire Agreement:</span>  These terms and conditions constitute the entire agreement between you and us with respect to the use of our mobile application.

            <br></br><br></br> <span className='boldText'>Contact Information:</span>  If you have any questions or concerns about these terms and conditions, please contact us through the email or contact information provided within the mobile application.

            <br></br><br></br> By creating a new account on our mobile application, you confirm that you have read and understood these terms and conditions, and that you agree to be bound by them.
        </div>
    )
}

export default TermsConditions