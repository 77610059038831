import React from 'react'
import { NavHashLink } from 'react-router-hash-link'

const Features = () => {
    const notDone = true

    if (notDone) {
        return (
            <div className='contactContainer' style={{ backgroundColor: '#3a3939' }}>
                <div style={{ fontSize: 'calc(16px + 0.4vw)' }}>Features Building... 🏗👷🏼‍♂️ </div>
                <p style={{ textAlign: 'center', fontSize: 'calc(12px + 0.3vw)' }}>Oops, we're not done changing yet! Don't worry, we'll be up and running soon!
                    <br></br> Instead of waiting around to be TOLD how good this app is, how about you try checking the application out and we're sure you'll be pleasantly surprised 😊</p>
                <NavHashLink to={'/#download'}>
                    <button className='button'>Go to Download</button>
                </NavHashLink>
            </div>
        )
    }
    return (
        <div>Features</div>
    )
}

export default Features