


export const FoodiiTags = [
    {
        "group": "Meal Type",
        "value": "Starter",
        "iconImage": require('../assets/icons/Starter.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Meal Type",
        "value": "Main",
        "iconImage": require('../assets/icons/Main.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Meal Type",
        "value": "Dessert",
        "iconImage": require('../assets/icons/Dessert.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Meal Type",
        "value": "Breakfast",
        "iconImage": require('../assets/icons/Breakfast.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Meal Type",
        "value": "Brunch",
        "iconImage": require('../assets/icons/Brunch.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Meal Type",
        "value": "Cold Drink",
        "iconImage": require('../assets/icons/Cold Drink.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Meal Type",
        "value": "Hot Drink",
        "iconImage": require('../assets/icons/Hot Drink.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Meal Type",
        "value": "Snack",
        "iconImage": require('../assets/icons/Snack.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Cuisine",
        "value": "African",
        "iconImage": require('../assets/icons/African.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Cuisine",
        "value": "American",
        "iconImage": require('../assets/icons/American.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Cuisine",
        "value": "Asian",
        "iconImage": require('../assets/icons/Asian.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Cuisine",
        "value": "British",
        "iconImage": require('../assets/icons/British.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Cuisine",
        "value": "Chinese",
        "iconImage": require('../assets/icons/Chinese.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Cuisine",
        "value": "French",
        "iconImage": require('../assets/icons/French.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Cuisine",
        "value": "Indian",
        "iconImage": require('../assets/icons/Indian.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Cuisine",
        "value": "Italian",
        "iconImage": require('../assets/icons/Italian.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Cuisine",
        "value": "Japanese",
        "iconImage": require('../assets/icons/Japanese.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Cuisine",
        "value": "Korean",
        "iconImage": require('../assets/icons/Korean.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Cuisine",
        "value": "Mexican",
        "iconImage": require('../assets/icons/Mexican.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Cuisine",
        "value": "Spanish",
        "iconImage": require('../assets/icons/Spanish.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Cuisine",
        "value": "Thai",
        "iconImage": require('../assets/icons/Thai.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Cuisine",
        "value": "Turkish",
        "iconImage": require('../assets/icons/Turkish.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Specialty",
        "value": "Bakery",
        "iconImage": require('../assets/icons/Bakery.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Specialty",
        "value": "Barbecue",
        "iconImage": require('../assets/icons/Barbecue.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Specialty",
        "value": "Bento",
        "iconImage": require('../assets/icons/Bento.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Specialty",
        "value": "Burger",
        "iconImage": require('../assets/icons/Burger.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Specialty",
        "value": "Comfort Food",
        "iconImage": require('../assets/icons/Comfort Food.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Specialty",
        "value": "Fusion",
        "iconImage": require('../assets/icons/Fusion.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Specialty",
        "value": "Noodles",
        "iconImage": require('../assets/icons/Noodles.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Specialty",
        "value": "Pasta",
        "iconImage": require('../assets/icons/Pasta.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Specialty",
        "value": "Platter",
        "iconImage": require('../assets/icons/Platter.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Specialty",
        "value": "Rice",
        "iconImage": require('../assets/icons/Rice.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Specialty",
        "value": "Salad",
        "iconImage": require('../assets/icons/Salad.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Specialty",
        "value": "Soup",
        "iconImage": require('../assets/icons/Soup.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Specialty",
        "value": "Spicy",
        "iconImage": require('../assets/icons/Spicy.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Specialty",
        "value": "Steak",
        "iconImage": require('../assets/icons/Steak.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Specialty",
        "value": "Sushi",
        "iconImage": require('../assets/icons/Sushi.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Specialty",
        "value": "Wrap",
        "iconImage": require('../assets/icons/Wrap.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Protein",
        "value": "Chicken",
        "iconImage": require('../assets/icons/Chicken.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Protein",
        "value": "Meat",
        "iconImage": require('../assets/icons/Meat.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Protein",
        "value": "Seafood",
        "iconImage": require('../assets/icons/Seafood.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Protein",
        "value": "Plant",
        "iconImage": require('../assets/icons/Plant.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    // {
    //     "group": "Dietary",
    //     "value": "Gluten Free",
    //     "iconImage": require('../assets/icons/Gluten Free.png'),
    //     "icon": null,
    //     "type": null,
    //     "name": null,
    //     "size": null,
    //     "iconAlt": null
    // },
    {
        "group": "Dietary",
        "value": "Halal",
        "iconImage": require('../assets/icons/Halal.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Dietary",
        "value": "Organic",
        "iconImage": require('../assets/icons/Organic.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Dietary",
        "value": "Nuts",
        "iconImage": require('../assets/icons/Nuts.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Dietary",
        "value": "Vegan",
        "iconImage": require('../assets/icons/Vegan.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Dietary",
        "value": "Vegetarian",
        "iconImage": require('../assets/icons/Vegetarian.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Others",
        "value": "Big Portion",
        "iconImage": require('../assets/icons/Big Portion.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Others",
        "value": "Other",
        "iconImage": require('../assets/icons/Other.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Others",
        "value": "Trendy",
        "iconImage": require('../assets/icons/Trendy.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    },
    {
        "group": "Others",
        "value": "Value for Money",
        "iconImage": require('../assets/icons/Value for Money.png'),
        "icon": null,
        "type": null,
        "name": null,
        "size": null,
        "iconAlt": null
    }
]