import React from 'react'
import { NavLink } from 'react-router-dom';
import './Landing.css'
import { Slide } from 'react-awesome-reveal';

const Landing = () => {

    return (
        <div className='LandingContainer'>
            <div className='LandingLeft' id='download'>
                <Slide triggerOnce direction='left' duration={1200}>
                    <div triggerOnce className='SloganText'>The Perfect Restaurant Companion App</div>
                    {/* <div triggerOnce className='SloganText2'>Making saving or planning restaurant experiences easy and personalised to you</div> */}
                    <div triggerOnce direction='right' className='DownloadText'>
                        Download Now!
                    </div>
                    {/* <div className='moving-shadow'>
                        <h3 data-shadow='Download Now!' >Download Now!</h3>
                    </div> */}
                    <section className='downloadButtonContainer'>
                        <NavLink target="_blank" to={'https://play.google.com/store/apps/details?id=com.sami246.foodi'} className='downloadButton'>
                            <img alt='google play store download button' className='iconImg' src={require('../../assets/download buttons/GooglePlayIcon.png')} />
                            <div>
                                <span className='downloadButtonSmallText'>GET IT ON</span>
                                <br></br>
                                Google Play
                            </div>
                        </NavLink>
                        <NavLink target="_blank" to={'https://apps.apple.com/us/app/foodii-restaurant-companion/id6447701868'} className='downloadButton'>
                            <img style={{ maxHeight: '90%' }} alt='apple store download button' className='iconImg' src={require('../../assets/download buttons/AppStoreBadge.png')} />
                            <div>
                                <span className='downloadButtonSmallText'>GET IT ON</span>
                                <br></br>
                                App Store
                            </div>
                        </NavLink>
                    </section>
                    {/* <div style={{ marginLeft: 200, paddingTop: 5, fontSize: 13 }}>*IOS Coming Soon</div> */}
                    {/* <section style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>

                    <div style={{ fontSize: '0.8rem', width: '90%', alignItems: 'center', backgroundColor: '#333333', paddingInline: '0.5vw', borderRadius: 30, textAlign: 'center', marginTop: '1vw' }}>
                        <p>
                            <span style={{ color: '#ff9a17', fontWeight: 700 }}>Capture, savor, and share </span> every exquisite dish and restaurant experience with Foodii.
                            <span style={{ color: '#ff9a17', fontWeight: 700 }}> Snap, rate, and tag </span> your favorite finds,
                            <span style={{ color: '#ff9a17', fontWeight: 700 }}> effortlessly organizing </span> them with our smart mapping features. Find the perfect spot for every occasion with curated
                            <span style={{ color: '#ff9a17', fontWeight: 700 }}> "Ideal Company"</span> categories. Never miss a culinary gem, and relive your inspiration from TikTok and Instagram.
                            <span style={{ color: '#ff9a17', fontWeight: 700 }}> Elevate </span>your foodie game - join Foodii today!
                        </p>
                    </div>
                </section> */}
                </Slide>
            </div>

            <div className='LandingRight'>
                {/* <NavLink to="/features"> */}
                <img alt='Never Be Lost on Where to Eat Again' style={{ height: '30vw', minHeight: '22vw' }} src={require('../../assets/Feature Graphic BIG-min.jpg')} />
                {/* </NavLink> */}
            </div>
            <div className='LandingRightSmall'>
                <NavLink to="/features">
                    <img alt='Feature Graphic' style={{ width: '100%' }} src={require('../../assets/Feature Graphic BIG-min.jpg')} />
                </NavLink>
            </div>
        </div>
    )
}

export default Landing