import React, { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import '@splidejs/react-splide/css/core';
import { Slide } from 'react-awesome-reveal';
import { TestimonialSlide } from './TestimonialSlide';
import './Testimonial.css';

const Testimonial = () => {
    const [startSlide, setStartSlide] = useState(0);
    const [visibleReviews, setVisibleReviews] = useState(calculateVisibleReviews());

    const testimonialData = [{
        name: 'Haseem Ibn Ahmad',
        image: null,
        rating: 5,
        date: new Date('12/10/2023'),
        review: "Honestly, really amazing, hope that more content gets added overtime to help everyone. Very unique app for a very unique situation",
        likes: 1,
        link: null,
        source: "Google Play Store"
    },

    {
        name: 'Esha A.',
        image: 'https://media.licdn.com/dms/image/C5603AQGBn1Ooo4Yv0w/profile-displayphoto-shrink_800_800/0/1562220434963?e=1712188800&v=beta&t=QecD0S94JA5FtM9TjOy1Y2SSGcOh9LD0SNaBJrU3iIU',
        rating: 5,
        date: new Date('12/10/2023'),
        review: "Exactly what I needed for all the TikTok finds",
        likes: 3,
        link: null,
        source: "Google Play Store"
    },
    {
        name: 'Anwar Abdi',
        image: 'https://media.licdn.com/dms/image/C4D03AQF8FPk4oTD8JQ/profile-displayphoto-shrink_800_800/0/1636072351148?e=1712188800&v=beta&t=6qsNuIY94SpgEcab8g2GqGuubTGQPMtFge4NwplJHh4',
        rating: 4,
        date: new Date('23/12/2023'),
        review: "Such a good app, always had so many food places to visit but never knew how to keep track of them all. Brilliant",
        likes: 1,
        link: null,
        source: "Google Play Store"
    },
    {
        name: 'Hasna Begum',
        image: null,
        rating: 5,
        date: new Date('12/10/2023'),
        review: "Amazing app, very easy to use and great for going to restaurants",
        likes: 3,
        link: null,
        source: "Google Play Store"
    },
    {
        name: 'Faisal',
        image: 'https://media.licdn.com/dms/image/C4D03AQH7Yk8u678NCQ/profile-displayphoto-shrink_800_800/0/1610556324502?e=1712188800&v=beta&t=uKNVvM67tWKfh2a5_eaAcmMxt96b-zHAn7ClwqwCQtY',
        rating: 4,
        date: new Date('02/12/2023'),
        review: "Really good for people who love eating out, just wish it would recommend me new places to go to in the future",
        likes: 3,
        link: null,
        source: 'Instagram'
    },
    {
        name: 'Omar Yusuf',
        image: null,
        rating: 5,
        date: new Date('05/11/2023'),
        review: "My Wife loves me now that I always know exactly how to please her cravings looool",
        likes: 3,
        link: null,
        source: "Apple Store"
    },

    ]

    useEffect(() => {
        const handleResize = () => {
            setVisibleReviews(calculateVisibleReviews());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setStartSlide((prevStart) => (prevStart + 1) % testimonialData.length);
        }, 5000); // Animation duration

        return () => clearInterval(interval);
    }, [startSlide, testimonialData.length]);


    function calculateVisibleReviews() {
        const viewportWidth = window.innerWidth;
        if (viewportWidth < 800) {
            return 1;
        } else if (viewportWidth < 1500) {
            return 2;
        } else {
            return 3;
        }
    }

    return (
        <div className='rating-container' id='client'>
            <Slide direction='left' triggerOnce>
                <span className='orange-heading'>testimonials</span>
                <div className='sub-heading'>what users say</div>
            </Slide>
            <div>
                <div className='testimonialWrapper'>
                    <Splide
                        hasTrack={false}
                        options={{
                            perPage: visibleReviews,
                            arrows: true,
                            pagination: false,
                            drag: 'free',
                            gap: '3rem',
                            snap: true,
                            autoplay: true,
                            interval: 12000,
                            speed: 1300,
                            resetProgress: true,
                            type: 'loop',
                            autoHeight: true
                        }}
                    >
                        <SplideTrack>
                            {testimonialData.map((item, index) => {
                                return (
                                    <SplideSlide key={index}>
                                        <TestimonialSlide item={item} />
                                    </SplideSlide>
                                );
                            })}
                        </SplideTrack>
                        <div className="splide__arrows">
                            <button className="splide__arrow splide__arrow--prev"><IoIosArrowBack /></button>
                            <button className="splide__arrow splide__arrow--next"><IoIosArrowForward /></button>
                        </div>
                    </Splide>
                </div>
            </div>
        </div>
    );
};

export default Testimonial;



