import React from 'react';
import './InfiniteTags.css'
import { FoodiiTags } from '../../../data/Foodii Icons';
import { Slide } from 'react-awesome-reveal';
import { handleIconSource } from '../../../lib/smallFunctions';
import { FaSearchLocation } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { MdMultipleStop } from "react-icons/md";
import { VscRunAbove } from "react-icons/vsc";

export const InfiniteTags = () => {

    const iconSize = 55
    const iconColor = 'white'
    const bulletPointData = [
        {
            icon: <FaSearchLocation size={iconSize} color={'#11A8E4'} />,
            image: require('../../../assets/icons/extra/Funnel.png'),
            emoji: '🔎',
            description: <div>Easily Find Your <span>Ideal Restaurants</span> with Specific Tags!</div>
        },
        {
            icon: <FaPlus size={iconSize} color={iconColor} />,
            image: require('../../../assets/icons/extra/Happy.png'),
            emoji: '➕',
            description: <div>The more you tag, the <span>easier</span> it is for you to find a place to eat the next time you start craving!</div>,
        },
        {
            icon: <MdMultipleStop size={iconSize} color={'#11A8E4'} />,
            image: require('../../../assets/icons/extra/Cookbook.png'),
            emoji: '😋',
            description: <div>Tags range from different categories, such as:<br></br> <span>Cuisine</span>, Specialty, <span>Protein</span> and more!</div>
        },
        {
            icon: <VscRunAbove size={iconSize} color={iconColor} />,
            image: require('../../../assets/icons/extra/Chevron_Up.png'),
            emoji: '👀',
            description: <div>All of the tags you can use are scrolling <span>above</span> this right now</div>
        },
    ]

    return (
        <div className='infinite-tags-container'>
            <div className='tag-list'>
                <div className='inner'>
                    {FoodiiTags.map((tag, index) => (
                        <div key={index} className='tag' style={{ backgroundColor: index % 2 === 0 ? '#11A8E4' : '#EE8A2C' }}>
                            <img src={handleIconSource(tag.value)} alt={tag.value} />
                            {tag.value}
                        </div>
                    ))}
                    <div className='fade'></div>
                </div>
            </div>
            <Slide triggerOnce duration={1700}>
                <div className='text-container'>
                    <div className='infinite-tags-text'>
                        <span>Tag </span>
                        the
                        <span> Taste</span>
                        {/* <br></br>Easily Find Your Ideal Restaurants with Specific Tags! */}
                    </div>
                    <div className='bullet-points-container'>
                        {bulletPointData.map((item, index) =>
                            <div key={index} className='bullet-point-box' >
                                <img src={item.image} style={{ aspectRatio: 1, width: 85, alignSelf: 'center', marginBottom: 7 }} />
                                {/* <div style={{ fontSize: 50 }}>{item.emoji}</div> */}
                                {/* <icon>{item.icon}</icon> */}
                                <div className='bullet-point-box-description'>{item.description}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='separator'></div>
            </Slide>
        </div>
    );
}
