import React from 'react'
import { SiTiktok, SiInstagram, SiTwitter } from "react-icons/si";
import './Footer.css'
import { NavLink, Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer>
            <div className="top">
                <div className="pages">
                    <ul>
                        <li><NavLink to="/features">Features</NavLink></li>
                        <li><NavLink to="/who_we_are">Who We Are</NavLink></li>
                        <li><NavLink to="/privacy_policy">Privacy Policy</NavLink></li>

                    </ul>
                    <ul>
                        <li><NavLink to="/updates">App Updates</NavLink></li>
                        <li><NavLink to="/contact">Contact Us</NavLink></li>
                        <li><NavLink to="/terms_and_conditions">Terms & Conditions</NavLink></li>
                    </ul>

                    {/* <ul>
                        <h3>Careers</h3>
                        <li><a href="#">Apply Online</a></li>
                        <li><a href="#">Available Positions</a></li>
                    </ul> */}


                </div>
                {/* <div className="newsletter">
                    <h3>Stay in Touch</h3>
                    <form>
                        <input
                            type="email"
                            name="newsletter_email"
                            id="newsletter_email"
                            placeholder="Email"
                        />
                        <input type="button" value="Submit" />
                    </form>
                </div> */}
            </div>
            <div className="social">

                <Link to='https://twitter.com/foodii_official' target="_blank"><SiTwitter size={35} /></Link>
                <Link to='https://www.instagram.com/foodii_official/' target="_blank"><SiInstagram size={35} /></Link>
                <Link to='https://www.tiktok.com/@foodii_official' target="_blank"><SiTiktok size={35} /></Link>

            </div>
            <div className="info">
                <div className="legal">
                    <NavLink to="/terms_and_conditions">Terms & Conditions</NavLink>
                    {/* <NavLink to="/privacy_policy">Privacy Policy</NavLink> */}
                </div>
                <div className="copyright">2023 Copyright &copy; Foodii</div>
            </div>
        </footer>
    )
}

export default Footer