import React, { useState } from 'react'
import './Contact.css'

const Contact = () => {
    const notDone = true
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [message, setMessage] = useState(null);
    const [submitted, setSubmitted] = useState(false)

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("handleSubmit")
        console.log(name, email, message)
        if (isValidEmail(email) && name && message) {
            setSubmitted(true)
        }
    }

    const isValidEmail = email => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    };

    if (notDone) {
        return (
            <div className='contactContainer' style={{ backgroundColor: '#3a3939' }}>
                <div style={{ fontSize: 'calc(16px + 0.4vw)', textAlign: 'center' }}>Contact us on <span className='boldText' style={{ color: '#11A8E4', textDecorationLine: 'underline' }}>thefoodiofficial@gmail.com</span></div>
                <p style={{ fontSize: 'calc(15px + 0.4vw)' }}>Contact Form Building... 🏗👷🏼‍♂️</p>
            </div>
        )
    }


    return (
        <div className='contactContainer'>
            {submitted ?
                <div className='headingText'>Thanks for the Message!</div> :
                <>
                    <div className='headingText'>Contact Us</div>
                    <form className='formContainer' id="contact-form" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <input type="text" className="form-control" value={name}
                                onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Email address:</label>
                            <input type="email" className="form-control" aria-describedby="emailHelp"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message:</label>
                            <textarea type='textarea' className="form-control" wrap='soft' rows={4} cols={40}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)} />
                        </div>
                        <button type="submit" className="button buttonContact">Submit</button>

                    </form>
                </>
            }
        </div>
    )
}

export default Contact