import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Features from '../pages/Features/Features';
import Updates from '../pages/Updates/Updates';
import TermsConditions from '../pages/Terms and Conditions/TermsConditions'
import PrivacyPolicy from '../pages/Privacy Policy/PrivacyPolicy';
import Who from '../pages/Who we Are/Who';
import Contact from '../pages/Contact/Contact';


const index = () => {
    return (
        <Routes>
            {/*  Dashboard */}
            <Route path='/' element={<Home />} />
            <Route path='/home' element={<Home />} />
            <Route path='/features' element={<Features />} />
            <Route path='/updates' element={<Updates />} />
            <Route path='/terms_and_conditions' element={<TermsConditions />} />
            <Route path='/privacy_policy' element={<PrivacyPolicy />} />
            <Route path='/who_we_are' element={<Who />} />
            <Route path='/contact' element={<Contact />} />



        </Routes>
    )
}

export default index