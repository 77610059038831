import React, { useState } from 'react'
import './Home.css'
import Landing from '../../components/landing/Landing'
import { SpringModal } from '../../components/modals/SpringModal'
import About from '../../components/about/About'
import MainFeatures from '../../components/main features/MainFeatures'
// import AdsComponent from '../../components/ads/AdsComponent'
import Testimonial from '../../components/smallComponents/Testimonial/Testimonial'

// import PocketBase from 'pocketbase';

// const pb = new PocketBase('http://127.0.0.1:8090');
// pb.autoCancellation(false)

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div style={{ backgroundColor: '#333333' }}>
            <SpringModal isOpen={isOpen} setIsOpen={setIsOpen} />
            <div className='landingContainer'>
                <Landing />
            </div>


            <div>
                <MainFeatures />
            </div>
            {/* <AdsComponent dataAdSlot={'2333305287'} /> */}
            <div>
                <About />
            </div>
            <div>
                <Testimonial />
            </div>
        </div>
    )
}

export default Home