import './App.css';
import { Analytics } from '@vercel/analytics/react';
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/NavBar';
import RouteHandler from './routes';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Analytics />
      <div className='FullScreen'>
        <Navbar />
        <RouteHandler />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
