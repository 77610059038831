import React, { } from 'react'
import './About.css'
import { HashLink } from 'react-router-hash-link';
import { useScreenSize } from 'react-screen-size-helper'
import { Slide } from 'react-awesome-reveal';
import Lottie from 'react-lottie';
import foodChoice from '../../assets/lottie/food_choice.json';

const About = () => {
    const {
        currentWidth,
        // currentHeight,
        // isLargeDesktop,
        // isDesktop,
        // isTablet,
        // isMobile
    } = useScreenSize({})

    const isWebView = currentWidth > 700

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: foodChoice,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <Slide duration={2000} triggerOnce>
            <div id="about" className='AboutContainer'>
                {/* <Lottie
                    options={defaultOptions}
                    height={400}
                    width={400}
                /> */}
                <div className='AboutBox'>
                    {/* <div className='headingText' style={{ textAlign: 'center' }}>What exactly is Foodii?</div> */}
                    <div className='AboutTextContainer'>
                        <span style={{ fontSize: isWebView ? '2.7rem' : '1.8rem', fontWeight: 'bold' }}>Are you a <span className='boldText shadow' style={{ color: '#e48100', fontSize: isWebView ? '2.8rem' : '2rem' }}>foodie</span> who loves 💖 exploring new restaurants and trying out different dishes?</span>

                        <br></br><br></br> Well Foodii is here to help you keep track and unlock 🔓 the potential of all your culinary experiences!

                        Our App lets you make use of all those <span className='boldText shadow' style={{ color: '#11A8E4', fontSize: isWebView && '2rem' }}>food pictures 📷</span> you have stored in your phone and <span className='boldText shadow' style={{ color: '#11A8E4', fontSize: isWebView && '2rem' }}>rate</span> ⭐ them so you can remember which dishes you loved 👍🏼 and which ones you didn't 👎🏼

                        <br></br>These feature comes in handy when your friends ask, <span className='boldText shadow' style={{ color: '#e48100', fontSize: isWebView && '3rem' }}>"Where can we get the best burger 🍔?"</span>

                        <br></br>- you can quickly and easily look up 🔎 all the burger places you've tried and decide which one is <span className='boldText shadow' style={{ color: '#11A8E4', fontSize: isWebView && '2rem' }}>the best. </span>

                        You can even add <span className='boldText shadow' style={{ color: '#11A8E4', fontSize: isWebView && '2rem' }}>tags</span> to your dishes, like <span className='boldText shadow' style={{ fontSize: isWebView && '1.5rem' }}>"spicy 🔥"</span> or <span className='boldText shadow' style={{ fontSize: isWebView && '1.5rem' }}>"vegetarian 🥕"</span>

                        to help you remember the unique characteristics of each, as well as simply ticking whether you <span className='boldText shadow' style={{ color: '#11A8E4', fontSize: isWebView && '2rem' }}>"would have again ✅"</span>

                        to <span className='boldText shadow' style={{ fontSize: isWebView && '2rem' }}>separate the good from the bad</span>

                        <br></br><br></br> With our app, you can also not only keep a <span className='boldText shadow' style={{ color: '#e48100', fontSize: isWebView && '2rem' }}>diary 📙</span>

                        of all the restaurants you've visited, you can also <span className='boldText shadow' style={{ color: '#11A8E4', fontSize: isWebView && '2rem' }}>pin 📌</span>

                        all of the brand new must try <span className='boldText shadow shadow' style={{ color: '#e48100', fontSize: isWebView && '2.5rem' }}>viral spots</span> that keep popping up, just add them to the "want to go" category and voila! <span className='boldText shadow' style={{ fontSize: isWebView && '2rem' }}>Never to be forgotten again 🧠</span>

                        <br></br><br></br>Our app also features powerful <span className='boldText shadow' style={{ color: '#11A8E4', fontSize: isWebView && '2rem' }}>sorting</span> and <span className='boldText shadow' style={{ color: '#11A8E4', fontSize: isWebView && '2rem' }}>filtering</span> options.

                        You can search for Dishes and Restaurants based on various criteria, such as <span className='boldText shadow' style={{ fontSize: isWebView && '1.7rem' }}>cuisine, price, and rating. </span>

                        This makes it easier for you to find the <span className='boldText shadow' style={{ color: '#e48100', fontSize: isWebView && '2.5rem', textDecoration: 'underline' }}>perfect</span> dining experience that suits your
                        <span className='boldText shadow' style={{ color: '#e48100', fontSize: isWebView && '1.9rem' }}> taste 👅, budget 💲 and mood 😋</span>

                        <br></br><br></br><span className='boldText shadow' style={{ fontSize: isWebView && '2.8rem' }}>So what are you waiting for?</span> Your Foodii adventure awaits!

                        <HashLink to={'#download'} smooth > <span className='boldText shadow' style={{ color: '#11A8E4', fontSize: isWebView && '3.2rem', textDecoration: 'underline' }}>Download</span> </HashLink>

                        our app now and start exploring all the amazing dining options around you!
                    </div>
                </div>

            </div >
        </Slide>
    )
}

export default About