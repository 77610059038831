export const data = [
    // {
    //     version: "3.2.3",
    //     date: "",
    //     description: [
    //         {
    //             main: 'Added New Tags',
    //             sub: [
    //                 "Added Barbecue tag"
    //             ]
    //         },
    //         {
    //             main: 'Bug Fixes',
    //             sub: [
    //                 "Fix Onboarding Button black background",
    //                 "Fix Random Restaurant Refresh Logic to stop buggy loading"
    //             ]
    //         },

    //     ],
    //     ios: true,
    //     android: true
    // },
    {
        version: "3.2.2",
        date: "19/12/2023",
        description: [
            {
                main: 'UI Changes',
                sub: [
                    "Fixed 1 week ago UI",
                    "Added Pricing Satisfaction icon to Recent List carousel"
                ]
            },
            {
                main: 'Added New Tags',
                sub: [
                    "Added New Tags such as: Noodles, Comfort Food, Pasta, Platter, Rice, Sushi and more"
                ]
            },
            {
                main: 'Bug Fixes',
                sub: [
                    "Fixed pricing satisfaction not being accurate when trying to update dish",
                    "Fixed Map Card Title Overlay",
                    "Fixed Filter Bug crash"
                ]
            },

        ],
        ios: true,
        android: true
    },
    {
        version: "3.2.1",
        date: "19/12/2023",
        description: [
            {
                main: 'Added Price Satisfaction',
                sub: [
                    "Now you can pick between three options to decide if you think a dish was overprice, priced fairly, or a bargain",
                    "This adds another cool way for you to keep track of dishes and filter through them in the future when trying to decide what to eat again"
                ]
            },
            {
                main: 'Added New Tags',
                sub: [
                    "Added: French, Fusion, Trendy and Turkish"
                ]
            },
            {
                main: 'Bug Fixes',
                sub: [
                    "Fix Restaurant Rating Sort"
                ]
            }

        ],
        ios: true,
        android: true
    },
    {
        version: "3.2.0",
        date: "08/12/2023",
        description: [
            {
                main: 'Added Public and Private',
                sub: [
                    "Allows you to keep some restaurants you add to your list away from the eyes of your friends by setting public or private",

                ]
            },
            {
                main: 'Added Dining Type Features',
                sub: [
                    "This feature lets you decide what kind of establishment the restaurant is, from a choice of Casual Dining, Fast Food, Fast Casual, Fine Dining, Food Truck/Stall, Buffet, Food Court, Cafe, Ghost Kitchen or Other",
                    "You can now filter from Dining Type also in case you want something more specific like Fine Dining"
                ]
            },
            {
                main: 'Added Service Charge Features',
                sub: [
                    "You can now keep track of whether restaurants charged you service charge. This may be common place in America but not so much so in the rest of the world so when it pops up, it can be surprising sometimes so make sure to check this feature to avoid scares!"
                ]
            },
            {
                main: 'UI/Performance Changes',
                sub: [
                    "Added comment introduction to Want To Go Carousel to easily see initial parts of a your comment to remember details about the restaurant without needing to go to Restaurant Details",
                    "Made some Home Screen carousels smaller",
                    "Change Add Tags to a click method instead of search and list so its user experience is nicer, quicker, easier to look at",
                    "Changed some checkboxes to stop needing to deselect the checkbox to reselect. Much more user friendly now :)",
                    "Removed Ads from displaying as part of Dish/Restaurant data and now displays as banner to improve performance",
                    "Added cache to many places to keep you from pressing the same buttons again and again when we can",
                    "Changed the filter functionality to only update filters upon save which smooths user experience considerably",
                    "Made it so zoom level only updates if changing between below to above limit or vice versa to stop unnecessary re-renders"
                ]
            },
            {
                main: 'Bug Fixes',
                sub: [
                    "Fix Edit Restaurant Modal Showing in scenarios when it shouldn't"
                ]
            }

        ],
        ios: false,
        android: false
    },
    {
        version: "3.1.1 & 3.1.2 ",
        date: "21/11/2023",
        description: [
            {
                main: 'Bug Fixes',
                sub: [
                    "Fix adding new dish and new restaurant",
                    "Fix App Marker when showing google map marker when adding restaurant",
                    "Fix when entering Add Dish Screen with no price Crash"
                ]
            }
        ],
        ios: true,
        android: true
    },
    {
        version: "3.1.0",
        date: "15/11/2023",
        description: [
            {
                main: 'New Categories Added!',
                sub: ["'Forgettable' and 'Never Again' have been added as potential categories so that you can even keep track of the places you didn't like and even worse, would never want to go to again!",
                    "The two new categories also do not show up in your personalised map unless you filter for them, so that you can focus on the ones you actually like and want to see"]
            },
            {
                main: 'New Tags Added',
                sub: [
                    'African, Fast Food, Mediterranean, Seafood, Vegan Tags have been added',
                    "Be more specific with your dishes and restaurants with these new tags, let us know what other tags you'd like to see in the future too!"
                ]
            },
            {
                main: 'Improvements to User Experience',
                sub: [
                    "Now when a user adds a new dish from a restaurant that is categorized as 'Want to Go', the user can quickly and easily either change the category on the pop-up or be navigated to the restaurant post to change everything in that post without having to navigate to it themselves.",
                    "List Displays are now cached so they remain how the user last chose them to be",
                    "New Image Placeholders to differentiate between Restaurants and Dishes"
                ]
            }
        ],
        ios: true,
        android: true
    },
    {
        version: "3.0.0",
        date: "26/10/2023",
        description: [
            {
                main: 'Friends Added!',
                sub: ['New Friend tab has been added where you can follow Users by Username and keep up to date with each others list of restaurants (Dishes may be available in later update)',
                    'Each Friend will have their own list which you can search, sort and filter just like your own, and then you can also copy any restaurants that you might like and not have on your list already for you to have on your very own list',
                    'Perfect for food bloggers who would want one place where everyone can find all their discoveries (and then video links) instead of losing them all in the endless social media feeds']
            },
            {
                main: 'Small Changes',
                sub: [
                    'Made default sort for lists to be latest created',
                    'Reduced frequency of AD popups for better user experience',
                    'Updated Home Page to include Carousel of latest updates from Friends',


                ]
            }

        ],
        ios: true,
        android: true
    },
    {
        version: "2.1.1",
        date: "17/10/2023",
        description: [
            {
                main: 'Necessary Update !!!',
                sub: ['Changes have been made to the way Opening Hours are stored and therefore older versions will not work in some occasions and crash so please update to use app properly!']
            },
            {
                main: 'UI Fixes',
                sub: ['Fixed Random Card Alignment', 'Fixed Status Bar issue for iPhone 14 Pro devices',]

            },
            {
                main: 'Bug Fixes',
                sub: ['Fixed Price and Date issues on Add Dish where date would be reset to current date and Price would not be shown when updating older posts as well as no decimal point for ios',
                    'Fixed Opening Hours discrepancies']
            }

        ],

        ios: true,
        android: true
    },
    {
        version: "2.1.0",
        date: "12/10/2023",
        description: [
            {
                main: 'Apple IOS App Available !!!',
                sub: ['After a long hard slog to get everything in order, the app is finally available on IOS with all of the same amazing features you can use on Android!',
                    'Android will catch up to IOS from next version onwards!']
            },
            {
                main: 'Added Skip to onboarding'
            },
            {
                main: 'UI Changes',
                sub: ['Added white border on selected markers on the map', 'Added change in zoom level to see restaurant names on map', 'Changed Category UI on Map Cards',]
            }
        ],
        ios: true,
        android: false
    },
    {
        version: "2.0.8",
        date: "25/09/2023",
        description: [
            {
                main: 'Bug Fixes',
                sub: ['Fixed Opening Time display bug with "Closed" timings', 'Fixed Linking to Google URL that sends user to Google Maps Pages of Restaurant']
            }
        ],
        ios: false,
        android: true
    },
    {
        version: "2.0.7",
        date: "22/09/2023",
        description: [
            {
                main: 'Added Opening Times',
                sub: ['Added to Restaurant Details', 'Added as a filter so users can now filter for only the restaurants currently open']
            },
            {
                main: 'Added Memoisation to list displays for performance improvements',
            },
            {
                main: 'UI Changes',
                sub: ['Home Page heading changes']
            },
            {
                main: 'Bug Fixes',
                sub: ['Fixed Restaurant filter display mix up', 'Fixed delete old images restaurant issue']
            }
        ],
        ios: false,
        android: true
    },
    {
        version: "2.0.6",
        date: "15/09/2023",
        description: [
            {
                main: 'Added Opening Times to be saved to Database when choosing Google Restaurant',
            },
            {
                main: 'User State Saved to stop from constant logging in again',
            },
            {
                main: 'Streamline queries for performance improvements',
            }
        ],
        ios: false,
        android: true
    },
    {
        version: "2.0.5",
        date: "13/09/2023",
        description: [
            {
                main: 'Added Placeholder for Home Page Carousels on load',
            },
        ],
        ios: false,
        android: true
    },
    {
        version: "2.0.4",
        date: "13/09/2023",
        description: [
            {
                main: 'Environment Variables issue fixed',
            },
        ],
        ios: false,
        android: true
    },
    {
        version: "2.0.0",
        date: "13/09/2023",
        description: [
            {
                main: 'Major Changes in Database',
                sub: ['Migration of all functionality to use Supabase instead of Firebase', 'Removed Firebase']
            },
            {
                main: 'More IOS fixes'
            },
            {
                main: 'UI Changes',
                sub: ['Settings Screen changed to Profile', 'Icon Changes', 'Profile can add picture', 'NavBar includes profile picture', 'Added pop up to stop restaurant delete if Dish post is linked']
            },
            {
                main: 'Bug Fixes',
                sub: ['Add dish would add twice sometimes', 'Changes to make targeting to android 33 +', 'Fix badly formatted links click', 'Added Deep-linking']
            },
            {
                main: 'Added Ability to add non-Google restaurant',
                sub: ['Users are still advised to use Google taken restaurants for best experience but can now choose to add restaurants that are not found on Google and specify and address for said place']
            }
        ],
        ios: false,
        android: true
    },
    {
        version: "1.3.6",
        date: "11/07/2023",
        description: [
            {
                main: 'Attempts to publish IOS version',
                sub: ['Much progress with aligning all the UI and features but unknown issue causing delay in creating production build']
            },
        ],
        ios: false,
        android: true
    },
    {
        version: "1.3.4",
        date: "29/06/2023",
        description: [
            { main: 'Added Names to map markers on certain zoom level' },
            {
                main: 'Added more Restaurant Filters on both map view and list view',
                sub: ['Wifi Available', 'Would Have Again']
            }

        ],
        ios: false,
        android: true
    },
    {
        version: "1.3.3",
        date: "13/06/2023",
        description: [
            { main: 'Added Name Tags to Ideal Company Filter' }
        ],
        ios: false,
        android: true
    },
    {
        version: "1.3.2",
        date: "08/06/2023",
        description: [
            { main: 'Fix add Dish bug not adding correctly' }
        ],
        ios: false,
        android: true
    },
    {
        version: "1.3.1",
        date: "07/06/2023",
        description:
            [{
                main: 'Best Practice Changes',
                sub: ['Added Performance Monitoring', 'Add Count Best Practice']
            },
            {
                main: 'Bug Fixes',
                sub: ['Alert Modal Fix', 'Google not adding restaurants properly fix']
            },
            { main: 'Show Ideal Company in add restaurant page on load' }
            ],
        ios: false,
        android: true
    },
    {
        version: "1.3.0",
        date: "06/06/2023",
        description:
            [{
                main: 'Added new attributes to Restaurant',
                sub: ['Price Level', 'Wifi Available', 'Ideal Company']
            },
            {
                main: 'Major UI Fixes',
                sub: ['Added Responsiveness to some chip categories']
            },
            {
                main: 'Added new Filters',
                sub: ['Users can now filter from Tags and Rating for both Restaurants and Dishes...',
                    '... but also Price Level (user chosen) and Ideal Company for just Restaurants']
            }

            ],
        ios: false,
        android: true
    },
    {
        version: "1.2.9",
        date: "22/05/2023",
        description:
            [{
                main: 'Added ability to copy details to restaurant when adding new dish',
                sub: ['When an user adds a new Dish but also for a new restaurant, they will prompted to copy over some of that data to the newly added restaurant post which will also be created.',
                    'The User can choose to copy over tags, comment, rating, would have again attributes as well as the ability to choose what category they want to make this newly added restaurant as to stop the need for users to traverse the application and repeat all these steps themselves.']
            },

            ],
        ios: false,
        android: true
    },
    {
        version: "1.2.8",
        date: "16/05/2023",
        description:
            [{
                main: 'Added Extra Google Restaurant information',
                sub: ['Now when adding a restaurant from Google we also save dine in, delivery, reservable information that is also displayed to users to help them make a decision on whether the place is right to eat at',
                    'Added Tags on Cards to show Users simply']
            }
                ,
            {
                main: 'Added NEW Tags',
                sub: ['French', 'Indian', 'Italian', 'Japanese', 'Mexican', 'Thai', 'Turkish']
            },
            {
                main: 'Fix Delete functionality',
            },
            {
                main: 'Fix Username log out issue',
            },
            {
                main: 'Added ability to Search through comments',
                sub: ['Users can now use the search bar on list views to search for comments, which allows for users to filter out their perfect places much easier by adding phrases and words that they can easily search for in the future']
            }

            ],
        ios: false,
        android: true
    },
    {
        version: "1.2.7",
        date: "11/05/2023",
        description:
            [{
                main: 'Change frequency of Ads',
                sub: ['Reduce the amount of times ads are shown to improve user experience']
            },
            {
                main: 'Fix on Username check',
                sub: ['Now checks on lowercase values also']
            },
            {
                main: 'Added Shuffle so new dish order is shown each time you are on Dishes Tab',
            },
            {
                main: 'Fixed add restaurant image storage bug',
            },
            ],
        ios: false,
        android: true
    },
    {
        version: "1.2.6",
        date: "07/05/2023",
        description:
            [{
                main: 'UI Changes',
            },
            ],
        ios: false,
        android: true
    },
    {
        version: "1.2.4",
        date: "06/05/2023",
        description:
            [{
                main: 'Fixed Google Login Error',
            },
            {
                main: 'Fixed Settings Screen UI issues'
            },
            {
                main: 'Added Initial Entry Modal',
                sub: ['Users are prompted with a pop up modal when first logging into their account so they are presented with an easy way to add a new dish or restaurant to start populating their account and making the most of the functionalities']
            },
            ],
        ios: false,
        android: true
    },
    {
        version: "1.2.3",
        description:
            [{
                main: 'Changes to Home Page',
                sub: ['Changed "Recently Want to Go" to add image']
            },
            {
                main: 'Changes to Navigation names (Backend Stuff)'
            },
            {
                main: 'Changed Icon padding'
            },
            {
                main: 'Added "Usernames"',
                sub: ['Added a check for Usernames on login', 'Added the need for Usernames to be able to identify users when pending friends list is implemented']
            },
            {
                main:
                    'Fixed Map Issue of always going to User Location on load'
            }],
        date: "05/05/2023",
        ios: false,
        android: true
    },
    {
        version: "1.2.1",
        description:
            [{
                main: 'Update App Values with new Name change',
            },
            { main: 'Fixed Ads Policy Issue' }],
        date: "20/03/2023",
        ios: false,
        android: true
    },
    {
        version: "1.2.0",
        description:
            [{
                main: 'Added Ads to Home Screen',
            },
            {
                main: 'Changed name of application from Foodi --> Foodii',
                sub: ['Also Changed icons and assets']
            },],
        date: "19/03/2023",
        ios: false,
        android: true
    },
    {
        version: "1.1.3",
        description:
            [{
                main: 'Added Images to Restaurants',
                sub: ['Users can now, same as dishes, choose to add an image to remember a restaurant by']
            }, {
                main: 'Added Load on Delete'
            },
            {
                main: 'UI changes to add Screens'
            },
            {
                main: 'Changed so Category is a must have when adding a new restaurant',
                sub: ['This makes it so the user can use the application to it;s fullest when sorting and filtering later on']
            }],
        date: "03/04/2023",
        ios: false,
        android: true
    },
    {
        version: "1.1.2",
        description:
            [{
                main: 'Added Specific Ad IDs for advertisement within application',
            },],
        date: "26/03/2023",
        ios: false,
        android: true
    },
    {
        version: "1.1.1",
        description:
            [{
                main: 'Fixed Google Sign in Overwriting problem with existing users',
            }],
        date: "20/03/2023",
        ios: false,
        android: true
    },
    {
        version: "1.1.0",
        description:
            [{
                main: 'Added Google Sign In Functionality',
                sub: ['Sign in with Existing Google Account, linked with Firebase']
            }],
        date: "21/03/2023",
        ios: false,
        android: true
    },
    {
        version: "1.0.0...",
        description: [{
            main: "Unfortunately nothing for this point backwards... 😓",
            sub: ""
        }],
        date: "20/03/2023",
        ios: false,
        android: true
    }
];