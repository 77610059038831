import React, { useState } from 'react'
import './MainFeatures.css'
import HoverCard from '../smallComponents/HoverCard/HoverCard'
import { TbMapPinSearch, TbFilter, TbNotebook, TbPinnedFilled, TbTargetArrow } from "react-icons/tb";
import { NavLink } from 'react-router-dom';
import { InfiniteTags } from '../smallComponents/InfiniteTags/InfiniteTags';
import { SwitchButton } from '../smallComponents/SwitchButton/SwitchButton';
import { Slide } from 'react-awesome-reveal';

const MainFeatures = () => {
    const [flipAll, setFlipAll] = useState(false);

    const handleFlipAll = () => {
        setFlipAll((prevFlip) => !prevFlip)
    }

    return (
        <div className='MainFeaturesContainer' id='main-features'>
            <div style={{ textAlign: 'center', paddingBottom: 20 }}>
                {/* <Slide>
                    <div className='headingText' >
                        Why Try
                        <span style={{ color: 'white' }}> Foodii</span>
                        ?
                    </div>
                </Slide> */}
                <div style={{ marginTop: 10 }}>
                    <InfiniteTags />
                </div>
                {/* <section style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>

                    <div style={{ fontSize: '1.5vw', width: '80%', alignItems: 'center', backgroundColor: '#ff9a17', paddingInline: 15, borderRadius: 80 }}>
                        <p>Foodii is your
                            <span style={{ color: '#333333', fontWeight: 700 }}> all in one</span> app for keeping track of your
                            <span style={{ color: '#333333', fontWeight: 700 }}> restaurant adventures.</span> <br></br>
                            Pin 📌 down
                            <span style={{ color: '#333333', fontWeight: 700 }}> where to eat</span> next. Save spots to ones you've been wanting to go to ever since you saw that
                            <span style={{ color: '#333333', fontWeight: 700 }}> viral video</span> online. Sort through them all depending on whatever your belly is feeling</p>
                    </div>
                </section> */}
                {/* <video height={500} width={500} loop autoPlay>
                    <source src={require('../../assets/7. Pick a Random Spot.mp4')} type="video/mp4" />
                </video> */}
                <Slide direction='up' triggerOnce>
                    <SwitchButton onClick={handleFlipAll} />
                </Slide>
                {/* <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', fontSize: '1.4rem' }}>
                    <div className='clickMe' style={{}} onClick={handleFlipAll} >Click me to flip all!</div>

                </section> */}
            </div>
            <Slide direction='up' triggerOnce>
                <section className='cardsContainer'>
                    <HoverCard
                        title={"Sort/Filter..."}
                        colour={"blue"}
                        icon={<TbFilter size={'5rem'} />}
                        flipAll={flipAll}
                        src={require('../../assets/phone screenshots/Phone Screenshots - 4.jpg')}
                    />
                    <HoverCard
                        title={"...Search"}
                        colour={"orange"}
                        icon={<TbMapPinSearch size={'5rem'} />}
                        flipAll={flipAll}
                        src={require('../../assets/phone screenshots/Phone Screenshots - 5.jpg')}
                    />
                    <HoverCard
                        title={"Pin"}
                        colour={"blue"}
                        icon={<TbPinnedFilled size={'5rem'} />}
                        flipAll={flipAll}
                        src={require('../../assets/phone screenshots/Phone Screenshots - 2.jpg')}
                    />
                    <HoverCard
                        title={"Journal"}
                        colour={"orange"}
                        icon={<TbNotebook size={'5rem'} />}
                        flipAll={flipAll}
                        src={require('../../assets/phone screenshots/Phone Screenshots - 3.jpg')}
                    />
                    <HoverCard

                        title={"Find"}
                        colour={"blue"}
                        icon={<TbTargetArrow size={'5rem'} />}
                        flipAll={flipAll}
                        src={require('../../assets/phone screenshots/Phone Screenshots - 6.jpg')}
                    />
                </section>
            </Slide>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '1vw' }}>
                <NavLink to={'/features'}>
                    <button className='button' style={{ backgroundColor: '#11A8E4', borderColor: 'white' }}>
                        Find Out More
                    </button>
                </NavLink>
            </div>

        </div>
    )
}

export default MainFeatures