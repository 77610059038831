import { useState } from 'react'
import { NavLink } from 'react-router-dom'
// import { ReactComponent as Hamburger } from '../../assets/icons/hamburger.svg'
// import { ReactComponent as Brand } from '../../assets/icons/logo.svg'
import './NavBar.css'
import { TiThMenu } from "react-icons/ti";
import { HashLink } from 'react-router-hash-link';

const NavBar = () => {
    const [showNavbar, setShowNavbar] = useState(false)

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }

    return (
        <nav className="navbar">
            <div className="navContainer">
                <div className="logo">
                    <NavLink to="/" style={{ color: 'white' }}>
                        <p className='logoText'>Foodii</p>
                    </NavLink>
                </div>
                <div className="menu-icon" onClick={handleShowNavbar}>
                    <TiThMenu size={'35px'} />
                </div>
                <div className={`nav-elements  ${showNavbar && 'active'}`}>
                    <ul>
                        <li>
                            <NavLink to="/">Home</NavLink>
                        </li>
                        <li>
                            <HashLink to="/#about" smooth>About</HashLink>
                        </li>
                        <li>
                            <HashLink to="/#main-features" smooth>Features</HashLink>
                        </li>
                        <li>
                            <NavLink to="/updates">Updates</NavLink>
                        </li>
                        <li>
                            <HashLink to="/#download" className='nav-download-button'>
                                DOWNLOAD
                            </HashLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavBar