import React from 'react'
import './SwitchButton.css'

export const SwitchButton = ({ onClick }) => {
    return (
        <div>
            <input onClick={onClick} type="checkbox" />
        </div>
    )
}
