import './Updates.css'
import './SuperResponsiveTableStyle.css'
import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { data } from '../../data/UpdatesData';


const Updates = () => {
    const notDone = false

    if (notDone) {
        return (
            <div className='updatesContainer'>
                <p style={{ fontSize: 'calc(15px + 0.4vw)' }}>Updates Page Building... 🏗👷🏼‍♂️</p>

            </div>
        )
    }

    return (
        <div className='updatesContainer'>
            <div className='headingText'>Updates </div>
            <Table className='Table'>
                <Thead className='TableHead'>
                    <Tr>
                        <Th>Version</Th>
                        <Th>Description</Th>
                        <Th>Date</Th>
                        <Th>Android</Th>
                        <Th>IOS</Th>
                    </Tr>
                </Thead>

                <Tbody className='TableBody'>
                    {data.map((row, index) => (
                        <Tr key={index}>
                            <Td className='cell'>{row.version}</Td>
                            <Td className='cell' id='description'>
                                {row.description?.map((point, index) => (
                                    <ul>
                                        <li key={index} className='main'>{point.main}</li>
                                        {point.sub &&
                                            <ul>
                                                {point.sub.map((sub, index) => (
                                                    <li className='sub'>
                                                        {sub}
                                                    </li>
                                                ))}
                                            </ul>
                                        }
                                    </ul>

                                ))}
                            </Td>
                            <Td className='cell'>{row.date}</Td>
                            <Td className='cell'>{row.android ? "Yes ✅" : "No ⛔"}</Td>
                            <Td className='cell'>{row.ios ? "Yes ✅" : "No ⛔"}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

        </div>
    )
}

export default Updates